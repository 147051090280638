.select-search .select2-selection.select2-selection--single {
    align-items: center;
    display: flex;
    height: 48px;
    width: 100%;
}

.select-search .select2-selection.select2-selection--single .select2-selection__rendered {
    padding: 0 12px;
    flex: 1;
}

.select-search .select2-selection.select2-selection--single .select2-selection__arrow {
    flex: 0 0 20px;
    margin-left: auto;
    position: relative;
}